import React, {ReactNode, useEffect, useRef, useState} from "react";
import {DatabaseMap, MapHistoryEntry} from "../../../../types";
import {useMapState} from "../../MapDisplay";
import {useGlobalState} from "../../../Menu/GlobalState";
import {Button, Collapse, CollapseProps, Drawer, Input, message, Skeleton, theme, Tooltip, Typography} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {shallow} from "zustand/shallow";
import ReactDiffViewer, {DiffMethod} from 'react-diff-viewer-continued';

interface Props {
  mapDiff: any
}

export const HistoryDrawerModalKey = 'history-drawer-open';
export const MapSettingsModalKey = 'map-settings';

export default function HistoryDrawer({mapDiff}: Props) {
  const amGM = useMapState((state) => state.amGM);
  const editingMapId = useGlobalState((state) => state.editingMapId);
  const openModals = useGlobalState((state) => state.openModals);
  const setModalOpen = useGlobalState((state) => state.setModalOpen);
  
  const [filterString, setFilterString] = useState<string>();
  
  const [mapHistory, setMapHistory] = useState<MapHistoryEntry[]>();
  const [editingMapData, setEditingMapData] = useGlobalState((state) => [state.editingMapData, state.setEditingMapData], shallow);
  const [localOpenedKeys, setLocalOpenKeys] = useState<string[]>();
  
  const ignoreNextRevert = useRef<boolean>(false);

  const { token } = theme.useToken();
  const [api, contextHolder] = message.useMessage();
  
  useEffect(() => {
    setLocalOpenKeys([]);
  }, [openModals]);
  
  // Track map history
  useEffect(() => {
    if (!amGM)
      return;
    // Don't track history when time is being tracked
    if (window.localStorage.getItem('currentTime'))
      return;

    const thisKey = `map-history-${editingMapId}`;

    const currentHistory = window.localStorage.getItem(thisKey);
    let parsedArray = currentHistory ? JSON.parse(currentHistory) as MapHistoryEntry[] : [];

    const filteredMapDiff = mapDiff?.filter((diff: any) => !((diff.path.length > 0 && diff.path[0] == 'drawings') || (diff.path[0] == 'timesettings')));
    
    if (filteredMapDiff && filteredMapDiff.length > 0) {
      if (ignoreNextRevert.current) {
        ignoreNextRevert.current = false;
        return;
      }
      console.log('adding change', filteredMapDiff);
      parsedArray.push({
        timestamp: Date.now(),
        diff: filteredMapDiff
      } as MapHistoryEntry);
    }
    if (parsedArray.length > 50)
      parsedArray = parsedArray.slice(1);

    window.localStorage.setItem(thisKey, JSON.stringify(parsedArray));
    setMapHistory(parsedArray);
  }, [mapDiff, editingMapId, amGM, setMapHistory]);
  
  const allDiffs = mapHistory?.sort((a, b) => b.timestamp - a.timestamp) ?? [];
  
  let collapseItems: CollapseProps['items'] = [];
  
  if (editingMapData && editingMapData.pois !== undefined) {
    let anyNotHandled = false;
    collapseItems = allDiffs.map((entry, index) => {
        let dateDifferenceMillis = new Date().getTime() - new Date(entry.timestamp).getTime();
        let labelShow: ReactNode = <></>;

        if (dateDifferenceMillis > 60000 * 30)
          labelShow = new Date(entry.timestamp).toLocaleString();
        else
          labelShow = `${Math.floor((dateDifferenceMillis) / 60000)} minutes ago`;

        const applyUpToIndex = (): {map: DatabaseMap, onlyThisChange: DatabaseMap, handled: boolean} => {
          let newEditingMapData = structuredClone(editingMapData) as DatabaseMap;
          let onlyThisChange: DatabaseMap = undefined;
          for (var i = 0; i <= index; i += 1) {
            if (i == index)
              onlyThisChange = structuredClone(newEditingMapData) as DatabaseMap;
            
            const entry = allDiffs[i].diff;

            let handledChange = true;

            for (var diffIndex = 0; diffIndex < entry.length; diffIndex += 1) {
              const diff: any = entry[diffIndex];

              if (diff.type == 'CHANGE') {
                if (diff.path.length == 2 && diff.path[1] == 'unsorted_players') {
                  // We removed the unsorted players and added them into the normal initiative list. Undo by reputting them in the unsorted list.
                  const currentInitiative = newEditingMapData.initiative;
                  currentInitiative.unsorted_players = diff.oldValue;
                  newEditingMapData.initiative = currentInitiative;
                  continue;
                }
                if (diff.path.length > 3 && diff.path[0] == 'initiative' && diff.path[1] == 'spots') {
                  const currentInitiative = newEditingMapData.initiative;
                  if (!currentInitiative)
                    continue;
                  currentInitiative.spots[diff.path[2]] = {
                    ...currentInitiative.spots[diff.path[2]],
                    [diff.path[3]]: diff.oldValue,
                  };
                  newEditingMapData.initiative = currentInitiative;
                  continue;
                }
                if (diff.path.length == 2 && diff.path[0] == 'initiative') {
                  const currentInitiative = newEditingMapData.initiative;
                  // @ts-ignore
                  currentInitiative[diff.path[1]] = diff.oldValue;
                  newEditingMapData.initiative = currentInitiative;
                  continue;
                }
                if (diff.path.length > 3 && diff.path[0] == 'pois' && diff.path[2] == 'status') {
                  const currentPois = newEditingMapData.pois;
                  const thatIndexPoi = currentPois[diff.path[1]];
                  const myStatus = thatIndexPoi.status;
                  myStatus[diff.path[3]] = diff.oldValue;
                  thatIndexPoi.status = myStatus;
                  currentPois[diff.path[1]] = thatIndexPoi;
                  newEditingMapData.pois = currentPois;
                  continue;
                }
                if (diff.path.length > 2 && diff.path[0] == 'pois') {
                  const currentPois = newEditingMapData.pois;
                  const thatIndexPoi = currentPois[diff.path[1]];
                  currentPois[diff.path[1]] = {
                    ...thatIndexPoi,
                    [diff.path[2]]: diff.oldValue,
                  };
                  newEditingMapData.pois = currentPois;
                  continue;
                }
                if (diff.path.length == 1) {
                  newEditingMapData = {
                    ...newEditingMapData,
                    [diff.path[0]]: diff.oldValue,
                  };
                  continue;
                }
              }
              
              if (diff.type == 'CREATE') {
                if (diff.path.length == 4 && diff.path[0] == 'pois' && diff.path[2] == 'status') {
                  // Added a new status, already had one. Remove that new one
                  const currentPois = newEditingMapData.pois;
                  const thatIndexPoi = currentPois[diff.path[1]];
                  const thatStatus = thatIndexPoi.status;
                  thatStatus.splice(diff.path[3], 1);

                  currentPois[diff.path[1]] = {
                    ...thatIndexPoi,
                    status: thatStatus,
                  };
                  newEditingMapData.pois = currentPois;
                  continue;
                }
                if (diff.path.length == 4 && diff.path[0] == 'initiative' && diff.path[1] == 'spots') {
                  // Added a new status, already had one. Remove that new one
                  const currentInitiative = newEditingMapData.initiative;
                  // @ts-ignore
                  const currentValue = currentInitiative.spots[diff.path[2]];
                  // @ts-ignore
                  delete currentValue[diff.path[3]];
                  // @ts-ignore
                  currentInitiative.spots[diff.path[2]] = currentValue;
                  newEditingMapData.initiative = currentInitiative;
                  continue;
                }
                if (diff.path.length == 3 && diff.path[0] == 'initiative' && diff.path[1] == 'spots') {
                  // Added a new initiative, delete the whole thing
                  const currentInitiative = newEditingMapData.initiative;
                  // @ts-ignore
                  delete currentInitiative.spots[diff.path[2]];
                  newEditingMapData.initiative = currentInitiative;
                  continue;
                }
                if (diff.path.length > 2 && diff.path[0] == 'pois') {
                  if (diff.path[2] == 'status')
                  {
                    // Added a status previously had none, set status to undefined.
                    const currentPois = newEditingMapData.pois;
                    const thatIndexPoi = currentPois[diff.path[1]];
                    if (!thatIndexPoi)
                      continue;
                    const thatStatus = thatIndexPoi.status;
                    if (thatStatus.length == 1)
                      currentPois[diff.path[1]] = {
                        ...thatIndexPoi,
                        status: undefined,
                      };
                    newEditingMapData.pois = currentPois;
                    continue;
                  } else {
                    const currentPois = newEditingMapData.pois;
                    const thatIndexPoi = currentPois[diff.path[1]];
                    // @ts-ignore
                    delete thatIndexPoi[diff.path[2]];
                    currentPois[diff.path[1]] = thatIndexPoi;
                    newEditingMapData.pois = currentPois;
                    continue;
                  }
                }
                // Adding a poi, revert by deleting the poi
                if (diff.path.length == 2 && diff.path[0] == 'pois') {
                  const currentPois = newEditingMapData.pois;
                  delete currentPois[diff.path[1]];
                  newEditingMapData.pois = currentPois;
                  continue;
                }
              }
              
              if (diff.type == 'REMOVE') {
                if (diff.path.length == 2 && diff.path[0] == 'pois') {
                  const currentPois = newEditingMapData.pois;
                  currentPois.splice(diff.path[1], 0, diff.oldValue);
                  newEditingMapData.pois = currentPois;
                  continue;
                }
                if (diff.path.length > 2 && diff.path[0] == 'pois' && diff.path[2] == 'status') {
                  const currentPois = newEditingMapData.pois;
                  const thatIndexPoi = currentPois[diff.path[1]];
                  
                  if (!thatIndexPoi.status || thatIndexPoi.status.length == 0) {
                    currentPois[diff.path[1]] = {
                      ...thatIndexPoi,
                      status: [diff.oldValue]
                    }
                  } else {
                    thatIndexPoi.status.splice(diff.path[3], 0, diff.oldValue);
                    currentPois[diff.path[1]] = {
                      ...thatIndexPoi
                    }
                  }
                  newEditingMapData.pois = currentPois;
                  continue;
                }
              }

              handledChange = false
              break;
            }

            if (!handledChange) {
              console.error('Could not rewind', JSON.stringify(entry));
              // api.error('Could not rewind to this point.');
              return {
                map: newEditingMapData,
                onlyThisChange: onlyThisChange,
                handled: false,
              };
            }
          }
          return {
            map: newEditingMapData,
            onlyThisChange: onlyThisChange,
            handled: true
          };
        }

        const {map: appliedToButton, onlyThisChange, handled} = anyNotHandled ? {map: editingMapData, onlyThisChange: editingMapData, handled: false} : applyUpToIndex();
        
        if (!handled)
          anyNotHandled = true;

        let button: ReactNode = (
          handled ? (
            <Button onClick={() => {
              // Update our history
              const thisKey = `map-history-${editingMapId}`;
              const currentHistory = window.localStorage.getItem(thisKey);
              let parsedArray = currentHistory ? JSON.parse(currentHistory) as MapHistoryEntry[] : [];
              parsedArray = parsedArray.slice(index + 1);
              window.localStorage.setItem(thisKey, JSON.stringify(parsedArray));
              setMapHistory(parsedArray);

              ignoreNextRevert.current = true;
              setEditingMapData(appliedToButton);
            }}>{index == 0 ? 'Revert' : 'Revert To Here'}</Button>
          ) : (
            <Tooltip title={"State not recoverable"}>
              <Button disabled={true} onClick={() => {}}>Error</Button>
            </Tooltip>
          )
        )

        return ({
          key: `${index}`,
          label: <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
            {labelShow}
            {button}
          </div>,
          children: <ReactDiffViewer
            splitView={true}
            useDarkTheme={true}
            compareMethod={DiffMethod.TRIMMED_LINES}
            codeFoldMessageRenderer={() => <></>}
            oldValue={JSON.stringify(appliedToButton, null, 2)}
            newValue={JSON.stringify(onlyThisChange, null, 2)}
          />,
        })
      })
  }
  
  return (
    <>
      {contextHolder}
      <Drawer title={"Map History"} placement={"right"} onClose={() => setModalOpen(HistoryDrawerModalKey,false)} open={openModals[HistoryDrawerModalKey] ?? false} width={600}>
        <Typography style={{ color: token.colorTextSecondary, textAlign: 'center'  }}>Expand a backup to see the change that was made</Typography>
        {/*<Typography style={{ color: token.colorTextTertiary, textAlign: 'center', marginBottom: 12 }}>Filter by date, changed field, etc...</Typography>*/}
        
        {/*<Input size="large" placeholder="Filter history..." prefix={<SearchOutlined />} style={{ flexGrow: 3, justifyContent: 'space-between', marginBottom: 12 }} value={filterString} onChange={(change) => setFilterString(change.target.value)}/>*/}
        {
          !mapHistory ?
            (
              <>
                <Skeleton />
              </>
            ) : (
              <>
                <Collapse items={collapseItems} activeKey={localOpenedKeys} onChange={(key) => setLocalOpenKeys(key as string[])} style={{ maxHeight: '80%', overflowY: 'auto'}} />
              </>
            )
        }
      </Drawer>
    </>
  );
}