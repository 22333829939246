import {CommandFunctionParameters} from "../CommandTemplate";
import {ScheduleKey} from "../../../../types";
import React from "react";
import {FaRegClock} from "react-icons/fa6";
import {GiCardPickup} from "react-icons/gi";
import {LuReplace} from "react-icons/lu";

export default [
  {
    icon: <FaRegClock/>,

    text: 'Track time in map',
    keywords: 'schedule',
    category: 'Time',

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData);
      newMapData.timesettings = {};
      props.setEditingMapData(newMapData);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (props.editingMapData.timesettings)
        return false;

      return true;
    }
  },
  {
    icon: <FaRegClock/>,

    text: 'Disable time in map',
    keywords: 'schedule',
    category: 'Time',
    priority: -5,

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData);
      newMapData.timesettings = undefined;
      props.setEditingMapData(newMapData);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.amGM)
        return false;

      if (!props.editingMapData.timesettings)
        return false;

      return true;
    }
  },
  {
    icon: <FaRegClock/>,

    text: 'Add position to schedule',
    keywords: 'schedule',
    category: 'Time',

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData);
      const existingSchedule = newMapData.timesettings!.schedule ?? {};

      const stepNow = parseFloat(window.localStorage.getItem('currentTime') ?? '0');

      let toReturn: ScheduleKey[] = [];
      if (existingSchedule[props.mouseOverFeature.map_text])
        toReturn = [...existingSchedule[props.mouseOverFeature.map_text]];

      toReturn.push({
        step: stepNow,
        position: [props.mouseOverFeature.coordinate_x, props.mouseOverFeature.coordinate_y]
      } as ScheduleKey)

      toReturn = toReturn.sort((a, b) => a.step - b.step);

      existingSchedule[props.mouseOverFeature.map_text] = toReturn;
      newMapData.timesettings.schedule = existingSchedule;

      props.setEditingMapData(newMapData);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.mouseOverFeature)
        return false;

      if (!props.amGM)
        return false;

      if (props.isFullMenu)
        return false;

      if (!props.mouseOverFeature.is_image)
        return false;

      if (!props.editingMapData.timesettings)
        return false;

      return true;
    }
  },
  {
    icon: <LuReplace/>,

    text: 'Add place on map to schedule',
    keywords: 'schedule',
    category: 'Time',

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData);
      const existingSchedule = newMapData.timesettings!.schedule ?? {};

      const stepNow = parseFloat(window.localStorage.getItem('currentTime') ?? '0');

      let toReturn: ScheduleKey[] = [];
      if (existingSchedule[props.mouseOverFeature.map_text])
        toReturn = [...existingSchedule[props.mouseOverFeature.map_text]];

      toReturn.push({
        step: stepNow,
        position: [props.mouseOverFeature.coordinate_x, props.mouseOverFeature.coordinate_y],
        isCreation: true,
      } as ScheduleKey)

      toReturn = toReturn.sort((a, b) => a.step - b.step);

      existingSchedule[props.mouseOverFeature.map_text] = toReturn;
      newMapData.timesettings.schedule = existingSchedule;

      props.setEditingMapData(newMapData);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.mouseOverFeature)
        return false;

      if (!props.amGM)
        return false;

      if (props.isFullMenu)
        return false;

      if (!props.mouseOverFeature.is_image)
        return false;

      if (!props.editingMapData.timesettings)
        return false;

      return true;
    }
  },
  {
    icon: <GiCardPickup/>,

    text: 'Add remove from map',
    keywords: 'schedule',
    category: 'Time',

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData);
      const existingSchedule = newMapData.timesettings!.schedule ?? {};

      const stepNow = parseFloat(window.localStorage.getItem('currentTime') ?? '0');

      let toReturn: ScheduleKey[] = [];
      if (existingSchedule[props.mouseOverFeature.map_text])
        toReturn = [...existingSchedule[props.mouseOverFeature.map_text]];

      toReturn.push({
        step: stepNow,
        position: [props.mouseOverFeature.coordinate_x, props.mouseOverFeature.coordinate_y],
        isDestroy: true,
      } as ScheduleKey)

      toReturn = toReturn.sort((a, b) => a.step - b.step);

      existingSchedule[props.mouseOverFeature.map_text] = toReturn;
      newMapData.timesettings.schedule = existingSchedule;

      props.setEditingMapData(newMapData);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.mouseOverFeature)
        return false;

      if (!props.amGM)
        return false;

      if (props.isFullMenu)
        return false;

      if (!props.mouseOverFeature.is_image)
        return false;

      if (!props.editingMapData.timesettings)
        return false;

      return true;
    }
  },
  {
    icon: <FaRegClock/>,

    text: 'Clear token schedule on this map',
    keywords: 'schedule',
    category: 'Time',

    perform: (props: CommandFunctionParameters) => {
      const newMapData = structuredClone(props.editingMapData);
      const existingSchedule = newMapData.timesettings!.schedule ?? {};
      if (existingSchedule[props.mouseOverFeature.map_text])
        delete existingSchedule[props.mouseOverFeature.map_text];
      newMapData.timesettings.schedule = existingSchedule;

      props.setEditingMapData(newMapData);
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.mouseOverFeature)
        return false;

      if (!props.amGM)
        return false;

      if (props.isFullMenu)
        return false;

      if (!props.mouseOverFeature.is_image)
        return false;

      if (!props.editingMapData.timesettings)
        return false;

      return true;
    }
  },
  {
    icon: <FaRegClock/>,

    text: 'Mark Off Schedule',
    keywords: 'schedule',
    category: 'Time',

    perform: (props: CommandFunctionParameters) => {
      const offSchedule = JSON.parse(window.localStorage.getItem('offSchedule') ?? '[]') as string[];
      offSchedule.push(props.mouseOverFeature.map_text);
      window.localStorage.setItem('offSchedule', JSON.stringify(offSchedule));
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.mouseOverFeature)
        return false;

      if (!props.amGM)
        return false;

      if (props.isFullMenu)
        return false;

      if (!props.mouseOverFeature.is_image)
        return false;

      if (!props.editingMapData.timesettings)
        return false;

      const offSchedule = JSON.parse(window.localStorage.getItem('offSchedule') ?? '[]') as string[];
      if (offSchedule.includes(props.mouseOverFeature.map_text))
        return false;

      return true;
    }
  },
  {
    icon: <FaRegClock/>,

    text: 'Mark On Schedule',
    keywords: 'schedule',
    category: 'Time',

    perform: (props: CommandFunctionParameters) => {
      const offSchedule = JSON.parse(window.localStorage.getItem('offSchedule') ?? '[]') as string[];
      offSchedule.splice(offSchedule.findIndex((s) => s === props.mouseOverFeature.map_text), 1);
      window.localStorage.setItem('offSchedule', JSON.stringify(offSchedule));
    },

    shouldInclude: (props: CommandFunctionParameters) => {
      if (!props.mouseOverFeature)
        return false;

      if (!props.amGM)
        return false;

      if (props.isFullMenu)
        return false;

      if (!props.mouseOverFeature.is_image)
        return false;

      if (!props.editingMapData.timesettings)
        return false;

      const offSchedule = JSON.parse(window.localStorage.getItem('offSchedule') ?? '[]') as string[];
      if (!offSchedule.includes(props.mouseOverFeature.map_text))
        return false;

      return true;
    }
  }
];